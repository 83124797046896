import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./Loader";
import moment from "moment";
import Pagination from "react-js-pagination";
import axios from "axios";
import "./AdminPayment.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PDFDocument from "./pdfDocument"; 
import { PDFDownloadLink } from '@react-pdf/renderer';

const AdminPayment = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [paymentData, setPaymentData] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const itemsPerPage = 10; 

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get("https://us-central1-the-physiora.cloudfunctions.net/api/getAllPayments");
        const payments = Object.values(response.data);

        // Sort the payments by timestamp in descending order
        payments.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

        setPaymentData(payments);
        setFilteredPayments(payments);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching payments data:", error);
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  useEffect(() => {
    filterPayments(searchTerm, fromDate, toDate);
  }, [searchTerm, fromDate, toDate, paymentData]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterPayments = (searchTerm, fromDate, toDate) => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = paymentData.filter((payment, index) => {
      const paymentDate = moment(payment.timestamp).toDate();
      const isWithinDateRange = 
        (!fromDate || paymentDate >= fromDate) && 
        (!toDate || paymentDate <= toDate);
  
      const matchesSearch = 
        (indexOfFirstItem + index + 1).toString().includes(lowercasedFilter) ||  // Search by S.No
        payment.patientName.toLowerCase().includes(lowercasedFilter) ||          // Search by Patient Name
        payment.phone.toLowerCase().includes(lowercasedFilter) ||                // Search by Phone
        payment.email.toLowerCase().includes(lowercasedFilter) ||                // Search by Email
        payment.paymentId.toLowerCase().includes(lowercasedFilter);              // Search by Payment ID
  
      return matchesSearch && isWithinDateRange;
    });
    setFilteredPayments(filteredData);
  };
  

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleToggleChange = async (paymentId, currentStatus) => {
    const newStatus = currentStatus === 'Yes' ? 'No' : 'Yes';
    try {
      await axios.put('https://us-central1-the-physiora.cloudfunctions.net/api/updateAppointmentStatus', {
        paymentId,
        appointmentStatus: newStatus
      });
      const updatedPayments = paymentData.map(payment =>
        payment.paymentId === paymentId ? { ...payment, appointmentStatus: newStatus } : payment
      );
      setPaymentData(updatedPayments);
      setFilteredPayments(updatedPayments);
    } catch (error) {
      console.error("Error updating appointment status:", error);
    }
  };

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPayments.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="App">
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="allpaymentadmin">
            <h1 className="text-center mb-4">Patient Payments</h1>
            <div className="col-md-10 patpay">
            <div className="row col-md-10">
            <div className="form-group col-md-4 mb-4">
              <label htmlFor="searchTerm">Search by Email or Payment ID</label>
              <input
                type="text"
                id="searchTerm"
                className="form-control input-shadow"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{width:"300px"}}
              />
            </div>
            <div className="form-group col-md-4 mb-4" style={{marginTop:"30px"}}>
              <label htmlFor="fromDate">From Date</label>
              <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control input-shadow"
                placeholderText="Select From Date"
              />
            </div>
            <div className="form-group col-md-4 mb-4" style={{marginTop:"30px"}}>
              <label htmlFor="toDate">To Date</label>
              <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                dateFormat="dd/MM/yyyy"
                className="form-control input-shadow"
                placeholderText="Select To Date"
              />
            </div>
            </div>
            <PDFDownloadLink
              document={<PDFDocument paymentsData={filteredPayments} />}
              fileName="patient_payments.pdf"
            
            >
              {({ loading }) => (loading ? 'Loading document...' : 'Download PDF')}
            </PDFDownloadLink>
            
            <div className="table-responsive row mb-4">
              <table id="listPatients1" cellPadding="0" cellSpacing="0" border="0">
                <thead className="tbl-header">
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Patient Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Appointment Date</th>
                    <th scope="col">Appointment Time</th>
                    <th scope="col">Booking Date</th>
                    <th scope="col">Email</th>
                    <th scope="col">Payment ID</th>
                    <th scope="col">Status</th>
                    <th scope="col">Appointment Status</th>
                  </tr>
                </thead>
                <tbody className="tbl-content">
                  {currentItems.map((payment, index) => (
                    <tr key={index}>
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>{payment.patientName}</td>
                      <td>{payment.phone}</td>
                      <td>{moment(payment.AppointmentDate).format("DD-MM-YYYY")}</td>
                      <td>{payment.AppointmentTime}</td>
                      <td>{payment.date}</td>
                      <td>{payment.email}</td>
                      <td>{payment.paymentId}</td>
                      <td>{payment.status === "captured" ? "success" : payment.status}</td>
                      <td>
                        <button
                          className={`toggle-btn ${payment.appointmentStatus === 'Yes' ? 'Yes' : 'No'}`}
                          onClick={() => handleToggleChange(payment.paymentId, payment.appointmentStatus)}
                        >
                          {payment.appointmentStatus || "No"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
           
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={filteredPayments.length}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
           </div>
           </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default AdminPayment;
