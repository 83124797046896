import React from 'react';
import './Services.css';

const Services = () => {
  const servicesData = [
    {
      Title: 'Orthopedic Rehabilitation',
      Description: 'Specialized treatment for musculoskeletal conditions, helping you recover from injuries and surgeries.',
      Button: { Link: '#', Text: 'Read More' },
      Icon: 'bi bi-heart-pulse'
    },
    {
      Title: 'Neurological Rehabilitation',
      Description: 'Comprehensive care for individuals with neurological conditions such as stroke, Parkinson’s disease, and spinal cord injuries.',
      Button: { Link: '#', Text: 'Read More' },
      Icon: 'bi bi-lightbulb'
    },
    {
      Title: 'Sports Physiotherapy',
      Description: 'Focused treatment and training programs for athletes to enhance performance and recover from sports-related injuries.',
      Button: { Link: '#', Text: 'Read More' },
      Icon: 'bi bi-trophy'
    }
  ];

  return (
    <div className="services1 section" id="services1">
   
      <div className="container1">
        <div className="row">
        <h2 style={{textAlign:"center",color:"#853f91"}}>Services</h2><br></br>
        <p style={{textAlign:"center",marginTop:"10px",marginBottom:"20px",}}>Our best physiotherapy services to the patients including fitness management program, deep tissue massages, acupuncture, pain relief therapy, electro-therapy, skilled manual therapy etc.</p><br></br><br></br>
          {servicesData.map((service, index) => (
            <div key={index} className="service-item1">
              <div className="icon">
                <i className={service.Icon}></i>
              </div>
              <div className="main-content">
                <h4>{service.Title}</h4>
                <p style={{textAlign:"justify"}}>{service.Description}</p>
                {/* <div className="main-button">
                  <a href={service.Button.Link}>{service.Button.Text}</a>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
