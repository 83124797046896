import React, { useState, useEffect } from "react";
import Header from "./Header";
import Section from "./Section";
import Footer from "./Footer";
import Services from "./Services";
import Button1 from "./Button1";
import Gallery from "./Gallery";
const ColorPalate=()=>{
    return(
        <div className="App">
        <Header />
        <br></br>
        <Gallery/>
        
        <Services/><br></br>
        <Section/> <br></br><br></br><br></br>
       
      <Footer />
    </div>
    );
}

export default ColorPalate;