import React from "react";
import "./slide.css";

const Slide=()=>{
    return(
        <>
  {/* Breadcrumbs */}
  <div className="breadcrumbs">
    <div className="overlay"></div>
    <div className="container">
      <div className="bread-inner">
        <div className="row">
          <div className="col-12">
            <h2 style={{textAlign:"center",color:"white"}}>About Us</h2>
            <ul className="bread-list" style={{textAlign:"center"}}>
              <li>
                <a href="/" style={{color:"#853f91"}}>Home</a>
              </li>
              {/* <li>
                <i className="icofont-simple-right" />
              </li>
              <li className="active">About Us</li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End Breadcrumbs */}
</>

    );
}

export default Slide;