import React, { useEffect } from 'react';
import './Alert.css'; // Import the CSS file for styling

export default function Alert({ open, 
    duration = 5000, 
    onClose = () => {}, 
    anchorOrigin = { vertical: 'top', horizontal: 'center' }, 
    type, 
    children 
}) {

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose(false);
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [open, duration, onClose]);

  if (!type || !children) {
    return null;
  }

  const handleClose = (event) => {
    onClose(false);
  };

  return (
    <div className={`alert ${type} ${open ? 'show' : ''}`} style={{ 
        top: anchorOrigin.vertical === 'top' ? '10px' : 'auto',
        bottom: anchorOrigin.vertical === 'bottom' ? '10px' : 'auto',
        left: anchorOrigin.horizontal === 'center' ? '50%' : 'auto',
        right: anchorOrigin.horizontal === 'right' ? '10px' : 'auto',
        transform: anchorOrigin.horizontal === 'center' ? 'translateX(-50%)' : 'none'
    }}>
      <span className="alert-close" onClick={handleClose}>&times;</span>
      {children}
    </div>
  );
}
