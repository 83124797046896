import React,{useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Image1 from "./images/Brain & Spine PNG.png";
import { FaEdit,FaTrash } from 'react-icons/fa';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { format, isValid } from 'date-fns';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './DoctorSchedule.css';
import { Link } from 'react-router-dom';
import Loader from "./Loader";

const DoctorSchedule=()=>{
    const [Name, setName] = useState("");
  const [RegistrationDate, setRegDate] = useState(new Date());
  const [Address, setAddress] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [Residence, setResidence] = useState("");
  const [Email, setEmail] = useState("");
  const [Fee, setFee] = useState("");
  const [Gender, setGender] = useState("");
//   const [UploadPhoto, setUploading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
//   const [photoName, setPhotoName] = useState('Choose file');
const [AccountNumber, setAccountNumber] = useState("");
const [PANNumber, setPANNumber] = useState("");
const [Age, setAge] = useState("");
const [CourseCompleted, setCourseCompleted] = useState("");
const [YearsOfCompletion, setYearsOfCompletion] = useState("");
const [UploadPhoto, setUploading] = useState(null);
const [photoName, setPhotoName] = useState('Choose file');


useEffect(()=>{
  document.title="Add Doctor | Physiora"
},[]);

const uploadFileHandler = (e) => {
  const file = e.target.files[0];
  if (file) {
    setUploading(file);
    setPhotoName(file.name);
  }
};

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = {
      Name,
      RegistrationDate,
      Address,
      PhoneNo,
      speciality,
      Residence,
      Email,
      Fee,
      Gender,
      AccountNumber,
      PANNumber,
      Age,
      CourseCompleted,
      YearsOfCompletion,
    };

    try {
    //   const storageRef = firebase.storage().ref();
    //   const fileRef = storageRef.child(UploadPhoto.name);
    //   await fileRef.put(UploadPhoto);

    //   const url = await fileRef.getDownloadURL();

    let imageUrl = '';
    if (UploadPhoto) {
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`DoctorImages/${UploadPhoto.name}`);
      await fileRef.put(UploadPhoto);
      imageUrl = await fileRef.getDownloadURL();
    }


    const formDataWithImage = {
      ...formData,
      imageUrl
    };

      const response = await fetch('https://us-central1-the-physiora.cloudfunctions.net/api/doctor-submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataWithImage),
      });

      const result = await response.json();
      if (response.ok) {
        setSuccessMessage(result.message);
        setErrorMessage('');

        setName('');
        setRegDate(new Date());
        setAddress('');
        setPhoneNo('');
        setSpeciality('');
        setResidence('');
        setEmail('');
        setFee('');
        setGender('');
        setAccountNumber('');
        setPANNumber('');
        setAge('');
        setCourseCompleted('');
        setYearsOfCompletion('');
        setUploading(null);
        setPhotoName('Choose file');
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error submitting form: ', error);
      setSuccessMessage('');
      setErrorMessage('Error submitting form.');
    }
  };

//   const uploadFileHandler = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setUploading(file);
//       setPhotoName(file.name);
//     }
//   };

  const [data, setData] = useState([]);

  
  const handleDelete = (doctorId) => {
    if (doctorId === undefined) {
      console.error('doctorId is undefined');
      return;
    }
    // Remove the corresponding data from Firebase Realtime Database
    firebase.database().ref('Doctor Details').orderByChild('doctorId').equalTo(doctorId)
      .once('value', snapshot => {
        snapshot.forEach(childSnapshot => {
          childSnapshot.ref.remove()
            .then(() => {
              console.log('Data deleted from Firebase');
              // Update the local state to reflect the deletion
              setData(prevData => prevData.filter(item => item.doctorId !== doctorId));
            })
            .catch(error => {
              console.error('Error deleting data from Firebase: ', error);
            });
        });
      });
  };

  
  useEffect(() => {
    const fetchData = async () => {
      const dbRef = firebase.database().ref('Doctor Details');
      dbRef.once('value', (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const dataArray = Object.keys(data).map(key => ({
            value: data[key].doctorId,
            name: data[key].Name,
            ...data[key]
          }));
          setData(dataArray);
        }else{
            setData([]);
        }
      });
    };

    fetchData();

    return () => {
        firebase.database().ref('adminData').off(); 
    };
  }, []);

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   return isValid(date) ? format(date, 'dd/MM/yyyy') : 'Invalid date';
  // };

    return(
        <div className="App">
            <Header/>
            <Loader/>
      <div className="container" style={{marginTop:"200px"}}>
      <h1 className="mt-4 color-bright" style={{textAlign:"center", color:"#853f91"}}>Add Doctor Details</h1><br></br>
        <div className="row">
         
          <form className="form-card" onSubmit={submitHandler} >
            <div className="formtype" style={{display:"flex"}}>
            <div className="card-details">
            <div className="card mb-4">
              <div className="card-header" style={{backgroundColor:"lightblue"}}>
                <h5>Personal Details</h5>
              </div>
              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-8">
                    <label className="font-weight-bold" htmlFor="inputName">Name</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputName" placeholder="Name" value={Name} onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div className="form-group col-md-8">
                    <label className="font-weight-bold" htmlFor="inputAddress">Address</label>
                    <textarea className="form-control input-bg-light-grey input-shadow3" id="inputAddress" placeholder="Address" rows="3" value={Address} onChange={(e) => setAddress(e.target.value)} />
                  </div>
                </div>
                <div className="form-row">
               
                  <div className="form-group col-md-8">
                    <label className="font-weight-bold" htmlFor="inputEmail">Email</label>
                    <input type="email" className="form-control input-shadow input-p" id="inputEmail" placeholder="Email" value={Email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  {/* <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputDaughter">Hospital Name</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputName" placeholder="Name" value={formData1.HospitalName} disabled/>
                </div> */}
                </div>
                <div className="form-row">
                  <div className="form-group col-md-8">
                    <label className="font-weight-bold" htmlFor="inputResidence">Residence</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputResidence" placeholder="Residence" value={Residence} onChange={(e) => setResidence(e.target.value)} />
                  </div>
                  <div className="form-group col-md-8">
                    <label className="font-weight-bold" htmlFor="inputGender">Gender</label>
                    <select className="form-control styled-select drop-down-patient-form" id="inputGender" value={Gender} onChange={(e) => setGender(e.target.value)}>
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="form-group col-md-8">
                  <label className="font-weight-bold" htmlFor="inputPhone">Phone Number</label>
                  <PhoneInput
                    className="form-control1 input-shadow input-p"
                    id="inputPhone"
                    placeholder="Phone no"
                    value={PhoneNo}
                    onChange={setPhoneNo}
                    defaultCountry="IN"
                    countryCodeEditable={false}
                  />
                </div>
                </div>
                {/* <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="font-weight-bold" htmlFor="inputPhoto">Upload Photo</label>
                    <div className="custom-file">
                      <input type="file" className="custom-file-input label-blue" id="inputPhoto" onChange={uploadFileHandler} />
                      <label className="custom-file-label" htmlFor="inputPhoto">{photoName}</label>
                    </div>
                    {UploadPhoto && (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
            </div>
          
            <div className="card mb-4" >
              <div className="card-header" style={{backgroundColor:"lightblue"}}>
                <h5>Professional Details</h5>
              </div>
              <div className="card-body">
                <div className="form-row">
                <div className="form-group col-md-8">
                  <label className="font-weight-bold" htmlFor="inputRegDate">Registration Date</label>
                  <div className="input-container">
                    <div className="date-picker-wrapper">
                      <DatePicker
                        selected={RegistrationDate}
                        onChange={(date) => setRegDate(date)}
                        className="form-control input-shadow1 date-picker-input"
                        id="inputRegDate"
                        placeholderText="Select date"
                      />
                      <i className="fa fa-calendar date-picker-icon" style={{ color: "blue" }}></i>
                    </div>
                  </div>
                </div>
                  <div className="form-group col-md-8">
                    <label className="font-weight-bold" htmlFor="inputSpeciality">Specialization</label>
                    <select className="form-control styled-select drop-down-patient-form" id="inputSpeciality" value={speciality} onChange={(e) => setSpeciality(e.target.value)}>
                      <option>Select</option>
                      <option value="Specialization1">Specialization 1</option>
                      <option value="Specialization2">Specialization 2</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-8">
                    <label className="font-weight-bold" htmlFor="inputFee">Fee</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputFee" placeholder="Fee" value={Fee} onChange={(e) => setFee(e.target.value)} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-8">
                    <label className="font-weight-bold" htmlFor="inputAccountNumber">Account Number</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputAccountNumber" placeholder="Account Number" value={AccountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-8">
                    <label className="font-weight-bold" htmlFor="inputPANNumber">PAN Number</label>
                    <input type="text" className="form-control input-shadow input-p" id="inputPANNumber" placeholder="PAN Number" value={PANNumber} onChange={(e) => setPANNumber(e.target.value)} />
                  </div>
                  <div className="form-group col-md-8">
                    <label className="font-weight-bold" htmlFor="inputAge">Age</label>
                    <input type="number" className="form-control input-shadow input-p" id="inputAge" placeholder="Age" value={Age} onChange={(e) => setAge(e.target.value)} />
                  </div>
                </div>
                <div className="form-row">
                <div className="form-group col-md-8">
                  <label className="font-weight-bold" htmlFor="inputPhoto">Upload Photo</label>
                  <div className="custom-file">
                    <input type="file" className="custom-file-input label-blue" id="inputPhoto" onChange={uploadFileHandler} />
                    <label className="custom-file-label" htmlFor="inputPhoto">{photoName}</label>
                  </div>
                  {UploadPhoto && (
                    <div className="d-flex justify-content-center">
                     
                    </div>
                  )}
                </div>
              </div>
              </div>
            </div>
           
            </div><br></br>
            <button type="submit" className="btn btn-primary save-p" style={{ width: "20%", textAlign: "center", marginLeft:"30%" }}>Save</button>
            {successMessage && (
            <div className="alert alert-success mt-3" role="alert">
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className="alert alert-danger mt-3" role="alert">
              {errorMessage}
            </div>
          )}
          </form>
         
        </div>
      </div><br></br>

      
  
      <div className="admin_table_section layout_padding-bottom">
  <div className="container">
    <div className="table-container">
      <h2>Doctor Details</h2>
      <div className="mb-3">
        <PDFDownloadLink
          document={<PDFDocument data={data} />}
          fileName="appointment_table.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? 'Loading document...' : 'Export in pdf'
          }
        </PDFDownloadLink>
      </div>
      <div className="table-responsive">
        <table id="adminTable">
          <thead>
            <tr>
              <th style={{ color: "black" }}>Doctor Id</th>
              <th style={{ color: "black" }}>Doctor Name</th>
              <th style={{ color: "black" }}>Email</th>
              <th style={{ color: "black" }}>Phone Number</th>
              <th style={{ color: "black" }}>Edit</th>
              <th style={{ color: "black" }}>Delete</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.doctorId}</td>
                <td>{item.Name}</td>
                <td>{item.Email}</td>
                <td>{item.PhoneNo}</td>
                <td>
                  <Link to={`/doctor-edit/${item.doctorId}`}>
                    <FaEdit style={{ marginRight: "10px", cursor: "pointer" }} />
                  </Link>
                </td>
                <td>
                  <FaTrash onClick={(e) => { e.preventDefault(); handleDelete(item.doctorId); }} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
    </div>
  </div>
</div>


            <Footer/>          
        </div>
    );
}


const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
    position: 'relative',
  },
  header: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f2c5fa',
    paddingVertical: 10,
  },
  headerTextContainer: {
    marginLeft: 10,
  },
  headerTitle: {
    fontSize: 25,
    color: '#853f91',
    textAlign: 'center',
  },
  headerSubtitle: {
    fontSize: 10,
    textAlign: 'center',
  },
  footer: {
    fontSize: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    backgroundColor: '#00ACED',
    padding: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginTop: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#853f91',
    backgroundColor: '#853f91',
    padding: 5,
  },
  tableCol: {
    width: '33.33%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
    padding: 5,
    backgroundColor: 'white',
    fontSize: '10px',
  },
  section: {
    marginBottom: 10,
  
  },
});

const PDFDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image src={Image1} style={{width: 50,height: 50}}/>
        <Text style={{textAlign:"center",fontSize:"25px",color:"#853f91",marginLeft:"100px"}}>THE PHYSIORA</Text><br></br>
        <Text style={{textAlign:"center",fontSize:"10px",marginLeft:"80px"}}>Doctor Appointment</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text>Doctor Id</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Doctor Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Email</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Phone Number</Text>
            </View>
          </View>
          {data.map((item, index) => (
            <View
              key={index}
              style={index % 2 === 0 ? { ...styles.tableRow, backgroundColor: '#DDDDDD' } : styles.tableRow}
            >
              <View style={styles.tableCol}>
                <Text>{item.doctorId}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Name}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.Email}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.PhoneNo}</Text>
              </View>
              {/* <View style={styles.tableCol}>
                <Text>{formatDate(item.RegistrationDate)}</Text>
              </View> */}
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer}>
        <Text>OMR,Egattur,Chennai</Text>
        <Text>thephysiora@gmail.com</Text>
        <Text>+91 89254 49512</Text>
      </View>
    </Page>
  </Document>
);
// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   return isValid(date) ? format(date, 'dd/MM/yyyy') : 'Invalid date';
// };

export default DoctorSchedule;