import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './Testimonial.css';
import Header from "./Header";
import Footer from "./Footer";
import 'bootstrap/dist/css/bootstrap.min.css';


const Testimonial=()=>{

    const [testimonialData, setTestimonialData] = useState([]);

    useEffect(() => {
      const fetchTestimonialData = async () => {
        const database = firebase.database();
        const testimonialRef = database.ref('reviews');
  
        try {
          const snapshot = await testimonialRef.once('value');
          const data = snapshot.val();
          console.log("data:",data);
          const testimonials = Object.values(data).filter(item => item !== null);
          console.log("testimonials:",testimonials);
          setTestimonialData(testimonials);
        } catch (error) {
          console.error('Error fetching testimonial data:', error);
        }
      };
  
      fetchTestimonialData();
    }, []);
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


    return(
      
        <div className="ftco-section testimony-section">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 text-center heading-section ftco-animate">
              <h2 className="mb-2" style={{color:"#853f91"}}>Testimonial</h2>
              <span className="subheading">Our Happy Patients Say</span>
            </div>
          </div>
          <div className="row justify-content-center ftco-animate">
            <div className="col-md-12">
              <Slider {...settings}>
              {testimonialData.map(testimonial => (
                  <div key={testimonial.time} className="item" style={{marginRight:"10px"}} >
                    <div className="card" style={{ border: "none", boxShadow: "0 4px 8px rgb(133, 63, 145)" }}>
                      <div className="card-body text-center" style={{ height: "500px"}}>
                        <h5 className="card-title" style={{ fontWeight: 'bold', color: "#853f91"}}>{testimonial.author_name}</h5>
                        <p className="card-text mt-1 text-justify" style={{textAlign:"justify"}}>{testimonial.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
   
    );
}

export default Testimonial;