import React, { Fragment } from 'react';
import './Success.css'; // Import the CSS file for styling

export default function Success({ result, setResult = () => {} }) {
  return (
    <Fragment>
      <div className="marginTop3">
        <div className="grid-container">
          <button
            className="button-back"
            onClick={() => setResult(null)}
          >
            <span className="arrow-back-icon">←</span> Back
          </button>
        </div>
        <div className="alert alert-success">
          <div className="alert-title">Success</div>
          {result.to && result.type !== 'ethereal' ? (
            <span>Successfully Email Sent to - <strong>{result.to}</strong></span>
          ) : null}
          {result.type === 'ethereal' ? (
            <div>
              <span>Successfully Email Generated.</span>
              <a className="button-view-mail" href={result.url} target="_blank" rel="noopener noreferrer">
                Click here to View Mail
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}
