import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./Loader";
import "./PaymentPage.css";
import PaymentButton from "./PaymentButton";

const PaymentPage =()=>{
    return(
        <div className="App">
            <Header/>
            <Loader/>
            <div className="payment_section layout_padding" style={{marginTop:"200px"}}>
            <div className="container">
                <h1 className='text-center mb-4' style={{color:"#853f91"}}>Payment</h1>
                <p>"Life happens, and we get it! If you can't make it to your appointment, you can carry over one missed appointment per year to another month, or even share it with a friend or family member!"</p>
                <p  className="Paymentp mb-4"><span>Notes:{" "}</span>Each appointment requires a payment. When making a payment, you can use a payment ID to book the appointment.</p>
                <div className="row justify-content-center mb-4">
                   
                    <div className="text-center mb-4 PaymentButton" id="razorpay-form">
                    <PaymentButton/>

                   
                    </div>
                    <div className="text-center paymentline">
                        <a href="/allpayment">Go To Latest Payment Details</a>
                    </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default PaymentPage;