import React, { useEffect, useState } from "react";
import "./Achievement.css";
import { FaHome, FaUserMd, FaSmile, FaTable } from 'react-icons/fa';

const Achievement=()=>{
    const [hospitalRooms, setHospitalRooms] = useState(0);
    const [specialistDoctors, setSpecialistDoctors] = useState(0);
    const [happyPatients, setHappyPatients] = useState(0);
    const [yearsOfExperience, setYearsOfExperience] = useState(0);


    useEffect(() => {
        const animateCounter = (setValue, finalValue) => {
          let startValue = 0;
          const duration = 2000;
          const incrementValue = 20; // Increment value
          const incrementTime = Math.abs(Math.floor(duration / (finalValue / incrementValue)));
    
          const timer = setInterval(() => {
            startValue += incrementValue;
            if (startValue >= finalValue) {
              startValue = finalValue;
              clearInterval(timer);
            }
            setValue(startValue);
          }, incrementTime);
        };
    
        animateCounter(setHospitalRooms, 3468);
        animateCounter(setSpecialistDoctors, 1);
        animateCounter(setHappyPatients, 4000);
        animateCounter(setYearsOfExperience, 32);
      }, []);
    
    return(
        <>
        {/* Start Fun-facts */}
        <div id="fun-facts" className="fun-facts section overlay">
        <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                {/* Start Single Fun */}
                <div className="single-fun">
                <FaHome className="icon" />
                  <div className="content">
                    <span className="counter">5</span>
                    <p>Star Rating</p>
                  </div>
                </div>
                {/* End Single Fun */}
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                {/* Start Single Fun */}
                <div className="single-fun">
                <FaUserMd className="icon" />
                  <div className="content">
                    <span className="counter">5+</span>
                    <p>Years of Experience</p>
                  </div>
                </div>
                {/* End Single Fun */}
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                {/* Start Single Fun */}
                <div className="single-fun">
                <FaSmile className="icon" />
                  <div className="content">
                    <span className="counter">5000+</span>
                    <p>Happy Patients</p>
                  </div>
                </div>
                {/* End Single Fun */}
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                {/* Start Single Fun */}
                <div className="single-fun">
                    <FaTable className="icon" />
                  <div className="content">
                    <span className="counter">800+</span>
                    <p>Satisfication</p>
                  </div>
                </div>
                {/* End Single Fun */}
              </div>
            </div>
          </div>
        </div>
        {/*/ End Fun-facts */}
      </>
      
    );
}

export default Achievement;