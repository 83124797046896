import React from "react";
import "./Doctor.css";
import person1 from "./images/person1.jpg";

const Doctor=()=>{
    return(
        <>
  {/* Team Start */}
  <div className="container-xxl py-5">
    <div className="container">
      <div
        className="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style={{ maxWidth: 600 }}
      >
        <h1 style={{color:"#853f91"}}>Our Experience Doctors</h1>
      </div>
      <div className="row g-4">
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="team-item position-relative rounded overflow-hidden">
            <div className="overflow-hidden">
              <img className="img-fluid" src={person1} alt="" />
            </div>
            <div className="team-text bg-light text-center p-4">
              <h5 style={{color: "#853f91"}}>Dr.C S Aravinda PT</h5>
              <p className="text-primary">Doctor</p>
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="team-item position-relative rounded overflow-hidden">
            <div className="overflow-hidden">
              <img className="img-fluid" src="img/team-2.jpg" alt="" />
            </div>
            <div className="team-text bg-light text-center p-4">
              <h5>Doctor Name</h5>
              <p className="text-primary">Department</p>
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="team-item position-relative rounded overflow-hidden">
            <div className="overflow-hidden">
              <img className="img-fluid" src="img/team-3.jpg" alt="" />
            </div>
            <div className="team-text bg-light text-center p-4">
              <h5>Doctor Name</h5>
              <p className="text-primary">Department</p>
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
          <div className="team-item position-relative rounded overflow-hidden">
            <div className="overflow-hidden">
              <img className="img-fluid" src="img/team-4.jpg" alt="" />
            </div>
            <div className="team-text bg-light text-center p-4">
              <h5>Doctor Name</h5>
              <p className="text-primary">Department</p>
              <div className="team-social text-center">
                <a className="btn btn-square" href="">
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-square" href="">
                  <i className="fab fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </div>
  {/* Team End */}
</>

    );
}

export default Doctor;