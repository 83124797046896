import React, { useEffect, useState } from "react";
import Header from "./Header";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import Footer from "./Footer";
import "./Contact.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from "./Loader.js";
import "./Contact1.css";
// import { database } from "./firebaseConfig";

// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB-bE-o-o6eXr2TwzOIUWDErCJXazVdgnw",
    authDomain: "the-physiora.firebaseapp.com",
    databaseURL: "https://the-physiora-default-rtdb.firebaseio.com",
    projectId: "the-physiora",
    storageBucket: "the-physiora.appspot.com",
    messagingSenderId: "441994762175",
    appId: "1:441994762175:web:05392776a4831181ebb30a",
    measurementId: "G-H4MMV8W49V"
  };

  firebase.initializeApp(firebaseConfig);
  const db = firebase.database();


function Contact() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [latestId, setLatestId] = useState(0);

  useEffect(() => {
    const fetchLatestId = async () => {
      try {
        const snapshot = await firebase.database()
          .ref("Contact Form")
          .orderByChild("Contact_id")
          .limitToLast(1)
          .once("value");
        if (snapshot.exists()) {
          const latestEntry = snapshot.val();
          const latestIdValue = Object.values(latestEntry)[0].Contact_id;
          setLatestId(latestIdValue);
        } else {
          setLatestId(0);
        }
      } catch (error) {
        console.error("Error fetching latest ID:", error);
      }
    };

    fetchLatestId();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const db = firebase.database();

    try {
      console.log("Latest ID:", latestId);
      const newId = latestId + 1;
      console.log("New ID:", newId);
      // Push form data to the database
      await db.ref("Contact Form").push({
        Contact_id: newId,
        Contact_name: name,
        Contact_email: email,
        Contact_subject: subject,
        Contact_message: message,
      });

      // Clear form fields and set success message
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    //   toast.success(`Message sent successfully`);
    } catch (error) {
      console.error("Error storing form data:", error);
    //   toast.error(`Failed to send message. Please try again.`);
    }
  };
  const [contactData, setContactData] = useState({
    sectionHeading: {
      title: "",
      subTitle: "",
      description: "",
    },
    specialOffer: {
      date: "",
      label: "",
      description: "",
      offerDiscount: "",
      offerText: "",
    },
  });

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const snapshot = await firebase.database().ref("Contact Section").once("value");
        if (snapshot.exists()) {
          const data = snapshot.val();
          const { Section_Heading, Special_Offer } = data;
          setContactData({
            sectionHeading: {
              title: Section_Heading.Title || "",
              subTitle: Section_Heading.Subtitle || "",
              description: Section_Heading.Description || "",
            },
            specialOffer: {
              description: Special_Offer.Description || "",
              date: Special_Offer.Date || "",
              label: Special_Offer.Label || "",
              offerDiscount: Special_Offer.Offer_Discount || "",
              offerText: Special_Offer.Offer_Text || "",
            },
          });
        } else {
          console.log("The contact data was not found in the database");
        }
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    };

    fetchContactData();
  }, []);

  useEffect(()=>{
    document.title="Contact | Physiora"
  },[]);

  return (
    <div className="App">
    <Header/><br></br><br></br><br></br><br></br>
    <Loader/>
        {/* Breadcrumbs */}
  <div className="breadcrumbs">
    <div className="overlay"></div>
    <div className="container">
      <div className="bread-inner">
        <div className="row">
          <div className="col-12">
            <h2 style={{textAlign:"center",color:"white"}}>Contact Us</h2>
            <ul className="bread-list" style={{textAlign:"center"}}>
              <li>
                <a href="/" style={{color:"#853f91"}}>Home</a>
              </li>
              {/* <li>
                <i className="icofont-simple-right" />
              </li>
              <li className="active">Contact Us</li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End Breadcrumbs */}
    <div className="contact-us section" id="contact">
      <div className="container">
        <div className="row pt-5">
          <div className="col-lg-6  align-self-center">
            <div className="section-heading">
              <h6 style={{color:"#853f91"}}>CONTACT US</h6>
              <h2>Feel Free To Contact Us Anytime</h2>
              <p>Thank you for visiting our physiotherapy clinic. We are dedicated to providing you with the highest quality care to help you achieve optimal health and wellness. </p>
              
            </div>
            <div className="mapspace">
              <iframe
                className="iframediv"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.2258272545982!2d80.22751997483877!3d12.828678687473731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525bbec19d78c9%3A0xefb6287fa6816325!2sTHE%20PHYSIORA%20Physiotherapy%20Clinic!5e0!3m2!1sen!2sin!4v1719576836912!5m2!1sen!2sin"
                width="500"
                height="300"
                style={{ border: 0, borderRadius:"10px",boxShadow:"inherit" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact-us-content">
              <form id="contact-form" onSubmit={handleSubmit} method="post">
                <div className="row">
                  <div className="col-lg-12">
                    <fieldset>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        placeholder="Your Name..."
                        autoComplete="on"
                        required
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        pattern="[^ @]*@[^ @]*"
                        placeholder="Your E-mail..."
                        required
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder="Subject..."
                        required
                        value={subject}
                        onChange={(event) => setSubject(event.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Your Message"
                        defaultValue={""}
                        required
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <button
                        type="submit"
                        id="form-submit"
                        className="orange-button"
                        style={{color:"#853f91"}}
                      >
                        Send Message Now
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
            {/* <ToastContainer /> */}
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default Contact;
