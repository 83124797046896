import React,{useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Services from "./Services";
import Doctor from "./Doctor";
import Loader from "./Loader.js";

const Servicepage=()=>{
    const servicesData = [
        {
          Title: 'Neck Pain',
          Description: 'Specialized treatment for musculoskeletal conditions, helping you recover from injuries and surgeries.',
          Button: { Link: '#', Text: 'Read More' },
          Icon: 'bi bi-heart-pulse'
        },
        {
          Title: 'Shoulder Pain',
          Description: 'Comprehensive care for individuals with neurological conditions such as stroke, Parkinson’s disease, and spinal cord injuries.',
          Button: { Link: '#', Text: 'Read More' },
          Icon: 'bi bi-lightbulb'
        },
        {
          Title: 'Back Pain',
          Description: 'Focused treatment and training programs for athletes to enhance performance and recover from sports-related injuries.',
          Button: { Link: '#', Text: 'Read More' },
          Icon: 'bi bi-trophy'
        }
    ];

    useEffect(()=>{
      document.title="Services | Physiora"
    },[]);

    return(
        <div className="App">
        <Header/><br></br><br></br><br></br><br></br>
        <Loader/>
        {/* Breadcrumbs */}
  <div className="breadcrumbs">
    <div className="overlay"></div>
    <div className="container">
      <div className="bread-inner">
        <div className="row">
          <div className="col-12">
            <h2 style={{textAlign:"center",color:"white"}}>Services</h2>
            <ul className="bread-list" style={{textAlign:"center"}}>
              <li>
                <a href="/" style={{color:"#853f91"}}>Home</a>
              </li>
              {/* <li>
                <i className="icofont-simple-right" />
              </li>
              <li className="active">Service Us</li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* End Breadcrumbs */}
        <Services/>
        <div className="services1 section" id="services1">
   
   <div className="container1">
     <div className="row">
       {servicesData.map((service, index) => (
         <div key={index} className="service-item1">
           <div className="icon">
             <i className={service.Icon}></i>
           </div>
           <div className="main-content">
             <h4>{service.Title}</h4>
             <p>{service.Description}</p>
             {/* <div className="main-button">
               <a href={service.Button.Link}>{service.Button.Text}</a>
             </div> */}
           </div>
         </div>
       ))}
     </div>
   </div>
 </div>
        <br></br><br></br>
        <Doctor/>
        <Footer/>
        </div>
    );  
}

export default Servicepage;