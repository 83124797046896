import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle } from 'react';
import './EmployeeInfo.css'; // Import the CSS file for styling
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import  EarningAndDeduction  from './EarningAndDeduction';

export default forwardRef(function EmployeeInfo({ templateData }, ref) {
  const [employee, setEmployee] = useState({ ...templateData.current.employee });
  const [doctorData, setDoctorData] = useState([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState('');



  const handleChange = (property) => {
    return (event) => {
      const value = event.target.value;
      const updatedEmployee = { ...employee, [property]: value };
      setEmployee(updatedEmployee);
      templateData.current.employee = updatedEmployee;
      console.log('Updated Employee:', updatedEmployee);
    };
  };

  const handleDoctorIdChange = async (event) => {
    const selectedId = event.target.value;
    setSelectedDoctorId(selectedId);

    const selectedDoctor = doctorData.find(doc => doc.doctorId === selectedId);
    if (selectedDoctor) {
      const updatedEmployee = {
        ...employee,
        name: selectedDoctor.Name || '',
        email: selectedDoctor.Email || '',
        position: selectedDoctor.speciality || '',
        id: selectedDoctor.doctorId || '',
        joiningDate: selectedDoctor.JoiningDate || '',
        accountNumber: selectedDoctor.AccountNumber || '',
        pfAccountNumber: selectedDoctor.PFAccountNumber || '',
        uan: selectedDoctor.UAN || '',
        // paidDays: selectedDoctor.paidDays || '',
        // lopDays: selectedDoctor.lopDays || '',
      };
       // Fetch attendance records
       const attendanceRef = firebase.database().ref('Doctor Attendance').orderByChild('doctorId').equalTo(selectedId);
       const attendanceSnapshot = await attendanceRef.once('value');
       const attendanceData = attendanceSnapshot.val();
 
       if (attendanceData) {
         const attendanceRecords = Object.values(attendanceData);
         const workingMinutes = calculateWorkingMinutes(attendanceRecords);
         const totalPaidDays = workingMinutes / 480; // Assuming 480 minutes per working day
         const totalLopDays = attendanceRecords.filter(record => record.status === 'Leave').length;
 
         updatedEmployee.paidDays = totalPaidDays;
         updatedEmployee.lopDays = totalLopDays - 1;
       }
      setEmployee(updatedEmployee);
      templateData.current.employee = updatedEmployee;
      // templateData.current.earnings.push({ name: 'Doctor Earnings', amount: selectedDoctor.earnings || 0 });
      console.log("Selected Doctor Data:", updatedEmployee);
    }
  };

  const calculateWorkingMinutes = (attendanceRecords) => {
    let totalMinutes = 0;
    attendanceRecords.forEach((record) => {
      const fromTotalMinutes = parseInt(record.fromHour) * 60 + parseInt(record.fromMinute);
    const toTotalMinutes = parseInt(record.toHour) * 60 + parseInt(record.toMinute);

    if (record.status === 'Present') {
      totalMinutes += toTotalMinutes - fromTotalMinutes;
    } else if (record.status === 'Half Day Leave') {
      totalMinutes += toTotalMinutes - fromTotalMinutes; // Assuming half day leave counts for half the time
    }
  });
  return totalMinutes;
};

  useImperativeHandle(ref, () => ({
    set(employee) {
      setEmployee(employee);
    },
    reset(employee) {
      setEmployee(employee);
    },
  }));

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = firebase.database().ref('Doctor Details');
      dbRef.once('value', (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const dataArray = Object.keys(data).map(key => ({
            doctorId: key,
            ...data[key]
          }));
          setDoctorData(dataArray);
        } else {
          setDoctorData([]);
        }
      });
    };

    fetchData();

    return () => {
      firebase.database().ref('Doctor Details').off();
    };
  }, []);


  return (
    
    <Fragment>
    
    <h2 className="mobileemployeehone" style={{textAlign:"center",color:"#853f91"}}></h2><br></br>
    <div className="grid-containerpayslip">
      <div className="grid-itempayslip">
        <label htmlFor="employeeId">Doctor ID</label>
        <select
          id="employeeId"
          className='form-control styled-select'
          name="employeeId"
          value={selectedDoctorId}
          onChange={handleDoctorIdChange}
          required
        >
          <option value="">Select Doctor ID</option>
          {doctorData.map(doc => (
            <option key={doc.doctorId} value={doc.doctorId}>
              {doc.doctorId}
            </option>
          ))}
        </select>
      </div>
      <div className="grid-itempayslip">
        <label htmlFor="employeeName">Doctor Name</label>
        <input
          type="text"
          id="employeeName"
          name="employeeName"
          value={employee.name}
          onChange={handleChange('name')}
          required
        />
      </div>
      <div className="grid-itempayslip">
        <label htmlFor="employeeEmail">Doctor Email</label>
        <input
          type="email"
          id="employeeEmail"
          name="employeeEmail"
          value={employee.email}
          onChange={handleChange('email')}
          required
        />
      </div>
      <div className="grid-itempayslip">
        <label htmlFor="position">Doctor Position</label>
        <input
          type="text"
          id="position"
          name="position"
          value={employee.position}
          onChange={handleChange('position')}
          required
        />
      </div>
      <div className="grid-itempayslip">
        <label htmlFor="joiningDate">Joining Date (YYYY-MM-DD)</label>
        <input
          type="date"
          id="joiningDate"
          name="joiningDate"
          value={employee.joiningDate}
          onChange={handleChange('joiningDate')}
          required
        />
      </div>
      <div className="grid-itempayslip">
        <label htmlFor="accountNumber">Account Number</label>
        <input
          type="text"
          id="accountNumber"
          name="accountNumber"
          value={employee.accountNumber}
          onChange={handleChange('accountNumber')}
          required
        />
      </div>
      <div className="grid-itempayslip">
        <label htmlFor="pfAccountNumber">PF Account Number</label>
        <input
          type="text"
          id="pfAccountNumber"
          name="pfAccountNumber"
          value={employee.pfAccountNumber}
          onChange={handleChange('pfAccountNumber')}
        />
      </div>
      <div className="grid-itempayslip">
        <label htmlFor="uan">Universal Account Number (UAN)</label>
        <input
          type="text"
          id="uan"
          name="uan"
          value={employee.uan}
          onChange={handleChange('uan')}
        />
      </div>
      <div className="grid-itempayslip">
        <label htmlFor="paidDays">Paid Days</label>
        <input
          type="number"
          id="paidDays"
          name="paidDays"
          value={employee.paidDays}
          onChange={handleChange('paidDays')}
          required
        />
      </div>
      <div className="grid-itempayslip">
        <label htmlFor="lopDays">LOP Days</label>
        <input
          type="number"
          id="lopDays"
          name="lopDays"
          value={employee.lopDays}
          onChange={handleChange('lopDays')}
          required
        />
      </div>
    </div>
    <EarningAndDeduction type="earning" templateData={templateData} selectedDoctorId={selectedDoctorId} />
    <EarningAndDeduction type="deduction" templateData={templateData} selectedDoctorId={selectedDoctorId} />
  </Fragment>
  );
});
