import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import DatePicker from 'react-datepicker';
import PhoneInput from 'react-phone-number-input';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import Loader from './Loader';
import './DoctorSchedule.css';

const EditDoctor = () => {
  const { doctorId } = useParams();
  const [Name, setName] = useState("");
  const [RegistrationDate, setRegDate] = useState(new Date());
  const [Address, setAddress] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [Residence, setResidence] = useState("");
  const [Email, setEmail] = useState("");
  const [Fee, setFee] = useState("");
  const [Gender, setGender] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [PANNumber, setPANNumber] = useState("");
  const [Age, setAge] = useState("");
  const [CourseCompleted, setCourseCompleted] = useState("");
  const [YearsOfCompletion, setYearsOfCompletion] = useState("");
  const [UploadPhoto, setUploading] = useState(null);
  const [photoName, setPhotoName] = useState('Choose file');
  const [fetchedImageUrl, setFetchedImageUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  useEffect(()=>{
    document.title="Update Doctor | Physiora"
  },[]);

  useEffect(() => {
    const fetchDoctorDetails = async () => {
      try {
        const response = await fetch(`https://us-central1-the-physiora.cloudfunctions.net/api/getdoctor/${doctorId}`);
        const result = await response.json();
        if (response.ok) {
          const {
            Name,
            RegistrationDate,
            Address,
            PhoneNo,
            speciality,
            Residence,
            Email,
            Fee,
            Gender,
            AccountNumber,
            PANNumber,
            Age,
            CourseCompleted,
            YearsOfCompletion,
            imageUrl
          } = result;

          setName(Name);
          setRegDate(new Date(RegistrationDate));
          setAddress(Address);
          setPhoneNo(PhoneNo);
          setSpeciality(speciality);
          setResidence(Residence);
          setEmail(Email);
          setFee(Fee);
          setGender(Gender);
          setAccountNumber(AccountNumber);
          setPANNumber(PANNumber);
          setAge(Age);
          setCourseCompleted(CourseCompleted);
          setYearsOfCompletion(YearsOfCompletion);
          setFetchedImageUrl(imageUrl || '');
          setPhotoName(imageUrl ? 'Photo uploaded' : 'Choose file');
        } else {
          throw new Error(result.error);
        }
      } catch (error) {
        console.error('Error fetching doctor details: ', error);
      }
    };

    fetchDoctorDetails();
  }, [doctorId]);

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(file);
      setPhotoName(file.name);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = {
      Name,
      RegistrationDate,
      Address,
      PhoneNo,
      speciality,
      Residence,
      Email,
      Fee,
      Gender,
      AccountNumber,
      PANNumber,
      Age,
      CourseCompleted,
      YearsOfCompletion,
    };

    try {
      let imageUrl = fetchedImageUrl; // Keep the existing image URL if no new file is uploaded
      if (UploadPhoto) {
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`DoctorImages/${UploadPhoto.name}`);
        await fileRef.put(UploadPhoto);
        imageUrl = await fileRef.getDownloadURL();
      }

      const formDataWithImage = {
        ...formData,
        imageUrl
      };

      const response = await fetch(`https://us-central1-the-physiora.cloudfunctions.net/api/doctor/${doctorId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataWithImage),
      });

      const result = await response.json();
      if (response.ok) {
        setSuccessMessage(result.message);
        setErrorMessage('');

        // Clear form fields
        setName('');
        setRegDate(new Date());
        setAddress('');
        setPhoneNo('');
        setSpeciality('');
        setResidence('');
        setEmail('');
        setFee('');
        setGender('');
        setAccountNumber('');
        setPANNumber('');
        setAge('');
        setCourseCompleted('');
        setYearsOfCompletion('');
        setUploading(null);
        setPhotoName('Choose file');
        setFetchedImageUrl(''); // Optionally clear the fetched image URL
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error updating doctor: ', error);
      setSuccessMessage('');
      setErrorMessage('Error updating doctor.');
    }
  };

  return (
    <div className="App">
      <Header />
      <Loader />
      <div className="container" style={{ marginTop: "200px" }}>
        <h1 className="mt-4 color-bright" style={{textAlign: "center", color: "#853f91"}}>Update Doctor Details</h1><br />
        <div className="row">
          <form className="form-card" onSubmit={submitHandler}>
            <div className="formtype" style={{display: "flex"}}>
            <div className="card-details">
              <div className="card mb-4">
                <div className="card-header" style={{ backgroundColor: "lightblue" }}>
                  <h5>Personal Details</h5>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputName">Name</label>
                      <input
                        type="text"
                        className="form-control input-shadow input-p"
                        id="inputName"
                        placeholder="Name"
                        value={Name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputAddress">Address</label>
                      <textarea
                        className="form-control input-bg-light-grey input-shadow3"
                        id="inputAddress"
                        placeholder="Address"
                        rows="3"
                        value={Address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputPhone">Phone Number</label>
                      <PhoneInput
                        className="form-control input-shadow input-p"
                        id="inputPhone"
                        placeholder="Phone no"
                        value={PhoneNo}
                        onChange={setPhoneNo}
                        defaultCountry="IN"
                      />
                    </div>
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputEmail">Email</label>
                      <input
                        type="email"
                        className="form-control input-shadow input-p"
                        id="inputEmail"
                        placeholder="Email"
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputResidence">Residence</label>
                      <input
                        type="text"
                        className="form-control input-shadow input-p"
                        id="inputResidence"
                        placeholder="Residence"
                        value={Residence}
                        onChange={(e) => setResidence(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputGender">Gender</label>
                      <select
                        className="form-control styled-select drop-down-patient-form"
                        id="inputGender"
                        value={Gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className="card mb-4">
                <div className="card-header" style={{ backgroundColor: "lightblue" }}>
                  <h5>Professional Details</h5>
                </div>
                <div className="card-body">
                  <div className="form-row">
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputRegDate">Registration Date</label>
                      <div className="input-container">
                        <div className="date-picker-wrapper">
                          <DatePicker
                            selected={RegistrationDate}
                            onChange={(date) => setRegDate(date)}
                            className="form-control input-shadow1 date-picker-input"
                            id="inputRegDate"
                            placeholderText="Select date"
                          />
                          <i className="fa fa-calendar date-picker-icon" style={{ color: "blue" }}></i>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-8">
                        <label className="font-weight-bold" htmlFor="inputSpeciality">Specialization</label>
                        <select
                          className="form-control styled-select drop-down-patient-form"
                          id="inputSpeciality"
                          value={speciality}
                          onChange={(e) => setSpeciality(e.target.value)}
                        >
                          <option value="">Select Specialization</option>
                          <option value="Specialization1">Specialization 1</option>
                          <option value="Specialization2">Specialization 2</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputFee">Fee</label>
                      <input
                        type="text"
                        className="form-control input-shadow input-p"
                        id="inputFee"
                        placeholder="Fee"
                        value={Fee}
                        onChange={(e) => setFee(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputAccountNumber">Account Number</label>
                      <input
                        type="text"
                        className="form-control input-shadow input-p"
                        id="inputAccountNumber"
                        placeholder="Account Number"
                        value={AccountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputPANNumber">PAN Number</label>
                      <input
                        type="text"
                        className="form-control input-shadow input-p"
                        id="inputPANNumber"
                        placeholder="PAN Number"
                        value={PANNumber}
                        onChange={(e) => setPANNumber(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputAge">Age</label>
                      <input
                        type="number"
                        className="form-control input-shadow input-p"
                        id="inputAge"
                        placeholder="Age"
                        value={Age}
                        onChange={(e) => setAge(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-8">
                      <label className="font-weight-bold" htmlFor="inputPhoto">Upload Photo</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input label-blue"
                          id="inputPhoto"
                          onChange={uploadFileHandler}
                        />
                        <label className="custom-file-label" htmlFor="inputPhoto">{photoName}</label>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        {fetchedImageUrl && (
                          <img
                            src={fetchedImageUrl}
                            alt="Fetched"
                            style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }}
                          />
                        )}
                        {UploadPhoto && (
                          <img
                            src={URL.createObjectURL(UploadPhoto)}
                            alt="Uploaded"
                            style={{ maxWidth: '100px', maxHeight: '100px' }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <button
              type="submit"
              className="btn btn-primary save-p"
              style={{ width: "20%", textAlign: "center", marginLeft: "40%" }}
            >
              Save
            </button>
            {successMessage && (
              <div className="alert alert-success mt-3" role="alert">
                {successMessage}
              </div>
            )}
            {errorMessage && (
              <div className="alert alert-danger mt-3" role="alert">
                {errorMessage}
              </div>
            )}
          </form>
        </div>
      </div><br /><br />
      <Footer />
    </div>
  );
}

export default EditDoctor;
