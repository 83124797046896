import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { capitalize } from 'lodash';
import { NumericFormat } from 'react-number-format';
import './EarningAndDeduction.css'; // Import the CSS file for styling
import axios from 'axios';

export default forwardRef(function EarningAndDeduction({ type, templateData, selectedDoctorId }, ref) {
  const [value, setValue] = useState({ name: '', amount: '' });
  const [doctorEarnings, setDoctorEarnings] = useState(null);
  const [doctorDeductions, setDoctorDeductions] = useState(null);

  const title = capitalize(type) + 's';
  const property = type + 's';
  const totalAmountTitle = type === 'earning' ? 'Total Salary' :
    type === 'deduction' ? 'Total Deductions' :
    type === 'reimbursement' ? 'Total Reimbursements' : '';

  useImperativeHandle(ref, () => ({
    set() {
      setValue({ name: '', amount: '' });
    },
    reset() {
      setValue({ name: '', amount: '' });
    }
  }));

  const onAddRows = () => {
    let array = templateData.current[property] || [];
    templateData.current[property] = [...array, value];
    setValue({ name: '', amount: '' });
  };

  const onRemoveRows = (index) => {
    templateData.current[property].splice(index, 1);
    setValue({ name: '', amount: '' });
  };

  const onInputAmountChange = (values) => {
    setValue({ ...value, amount: values.value });
  };

  useEffect(() => {
    const fetchDoctorEarningsAndDeductions = async (doctorId) => {
      if (!selectedDoctorId) return;
  
      try {
        const response = await axios.get(`https://us-central1-the-physiora.cloudfunctions.net/api/doctorEarnings?doctorId=${selectedDoctorId}`);
        const { earnings, deductions } = response.data;
        setDoctorEarnings(earnings);
        setDoctorDeductions(deductions);
  
        if (type === 'earning') {
          const updatedValue = { name: 'Salary', amount: earnings };
          setValue(updatedValue);
          templateData.current[property] = [...(templateData.current[property] || []), updatedValue];
        } else if (type === 'deduction') {
          const updatedValue = { name: 'Deductions', amount: deductions };
          setValue(updatedValue);
          templateData.current[property] = [...(templateData.current[property] || []), updatedValue];
        }
      } catch (error) {
        console.error('Error fetching doctor earnings and deductions:', error);
      }
    };
  
    if (selectedDoctorId !== 0) {
      fetchDoctorEarningsAndDeductions(selectedDoctorId);
    } else {
      // If selectedDoctorId is 0, change it to either 1 or 2 and fetch the earnings and deductions
      const newDoctorId = Math.random() < 0.5 ? 1 : 2;
      fetchDoctorEarningsAndDeductions(newDoctorId);
    }
  }, [selectedDoctorId, type]);
  

  

  return (
    <div className="earning-deduction-container">
      <h2 style={{ textAlign: "center", color: "#853f91" }}>{title}</h2>

      <div className="form-grouppayslip" style={{ marginTop: "10px" }}>
        <div className="form-itempayslip">
          <input
            type="text"
            id={`${type}-name`}
            name="name"
            placeholder='Name'
            value={value.name}
            onChange={(e) => setValue({ ...value, name: e.target.value })}
            readOnly={value.name === 'Salary' || value.name === 'Deductions'}
          />
        </div>
        <div className="form-itempayslip" style={{ marginLeft: "50px" }}>
          <NumericFormat
            id={`${type}-amount`}
            value={value.amount}
            thousandSeparator={true}
            placeholder='Amount'
            thousandsGroupStyle="lakh"
            prefix={'₹'}
            onValueChange={onInputAmountChange}
            allowNegative={false}
            customInput="input"
          />
        </div>
        <button
          style={{ width: "250px", marginLeft: "50px" }}
          type="button"
          className="buttonpayslip"
          onClick={onAddRows}
          disabled={!value.name || !value.amount}
        >
          Add
        </button>
      </div><br />

      {templateData.current[property] && templateData.current[property].length ? (
        <div className="table-container" style={{ paddingLeft: "35%", marginTop: "25px" }}>
          <table>
            <thead>
              <tr>
                <th style={{ color: "#853f91" }}>Name</th>
                <th style={{ color: "#853f91" }}>Amount (₹)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {templateData.current[property].map((row, index) => (
                <tr key={index}>
                  <td>{row.name}</td>
                  <td>
                    <NumericFormat
                      value={row.amount}
                      displayType={'text'}
                      thousandSeparator={true}
                      thousandsGroupStyle="lakh"
                      prefix={'₹'}
                    />
                  </td>
                  <td>
                    <button className='buttonpayslip' type="button" onClick={() => onRemoveRows(index)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td><b>{totalAmountTitle}</b></td>
                <td>
                  <b>
                    <NumericFormat
                      value={templateData.current[property].reduce((sum, row) => sum + Number(row.amount), 0)}
                      displayType={'text'}
                      thousandSeparator={true}
                      thousandsGroupStyle="lakh"
                      prefix={'₹'}
                    />
                  </b>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
});
