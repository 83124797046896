import React,{useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import "./Smdisplay.css";
import { InstagramEmbed } from "react-social-media-embed";

const Smdisplay=()=>{
    const [links, setLinks] = useState([]);
  const [titles, setTitles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://us-central1-the-physiora.cloudfunctions.net/api/get-links')
      .then(response => {
        setLinks(response.data.links);
        setTitles(response.data.titles);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

    return(
      <div className="scmedia" style={{ marginTop: "250px" }}>
      <h1 style={{ fontSize: "30px", textAlign: "center", color: "#853f91" }}>
        Our Viral Health Tips
      </h1>
      <div className="links-grids">
        {links.map((link, index) => (
          <div key={index} className="link-item">
          <button className="title-button">{titles[index]}</button>
            <InstagramEmbed url={link} width={320} />
          </div>
        ))}
      </div>
    </div>
    );
}

export default Smdisplay;
