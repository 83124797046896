import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from "./Loader";
import Multiselect from 'multiselect-react-dropdown';
import "./AttendanceDoctor.css";

const AttendanceDoctor = () => {
  const [doctorName, setDoctorName] = useState('');
  const [doctorNameId, setDoctorNameId] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [roomsLeft, setRoomsLeft] = useState('');
  const [successMessage2, setSuccessMessage2] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');

  const [calendarEvents, setCalendarEvents] = useState([]);
  const calendarRef = useRef(null);

  const [numberOfAdults, setNumberOfAdults] = useState('');
  const [numberOfChildren, setNumberOfChildren] = useState('');

  const [fromHour, setFromHour] = useState(0);
  const [fromMinute, setFromMinute] = useState(0);
  const [toMinute, setToMinute] = useState(0);
  const [toHour, setToHour] = useState(0);
  const [status, setStatus] = useState('');

  useEffect(()=>{
    document.title="Doctor Attendance | Physiora"
  },[]);



  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const database = firebase.database();
        const snapshot = await database.ref('/Doctor Details').once('value');
        const doctorsData = snapshot.val();

        const doctorsOptions = Object.keys(doctorsData).map(key => ({
          value: doctorsData[key].doctorId,
          name: doctorsData[key].Name,
          ...doctorsData[key]
        }));

        setOptions(doctorsOptions);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };

    fetchDoctors();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const database = firebase.database();
      const doctorsToSubmit = doctorName.includes('all') ? options : doctorName.map(selected => options.find(option => option.value === selected.value));
  
      let globalAttendanceId = 0;
  
      for (const doctor of doctorsToSubmit) {
        if (globalAttendanceId === 0) {
          const attendanceSnapshot = await database.ref('Doctor Attendance').orderByKey().limitToLast(1).once('value');
          attendanceSnapshot.forEach(childSnapshot => {
            const attendanceData = childSnapshot.val();
            if (attendanceData && attendanceData.attendanceId) {
              globalAttendanceId = Math.max(globalAttendanceId, attendanceData.attendanceId);
            }
          });
        }
  
        globalAttendanceId += 1;
  
        const newEventRef = database.ref('Doctor Attendance').push();
  
        const newEvent = {
          doctorId: doctor.doctorId,
          selectedDate: selectedDate.toISOString(),
          fromHour,
          fromMinute,
          toHour,
          toMinute,
          status,
          doctorName: doctor.Name,
          attendanceId: globalAttendanceId,
        };
  
        await newEventRef.set(newEvent);
  
        const title = newEvent.status === 'Present'
          ? `${newEvent.fromHour}:${newEvent.fromMinute} to ${newEvent.toHour}:${newEvent.toMinute}`
          : newEvent.status;
  
        const eventForCalendar = {
          title,
          start: new Date(newEvent.selectedDate),
          allDay: true,
          backgroundColor: getColorBasedOnCategory(newEvent.doctorId),
        };
  
        setCalendarEvents(prevEvents => [...prevEvents, eventForCalendar]);
      }
  
      setDoctorName([]);
      setDoctorNameId('');
      setSelectedDate(new Date());
      setFromHour(0);
      setFromMinute(0);
      setToHour(0);
      setToMinute(0);
      setStatus('');
  
      setSuccessMessage2('Form data submitted successfully.');
      setErrorMessage2('');
      console.log('Form data submitted successfully.');
    } catch (error) {
      setErrorMessage2('Error submitting form data. Please try again.');
      console.error('Error submitting form data:', error);
      setSuccessMessage2('');
    }
  };

  
  
  const [selectedDoctor, setSelectedDoctor] = useState(null);


  const handleDoctorChange = (event) => {
    const selectedId = event.target.value;
    const doctor = options.find(option => option.value === selectedId);
    setDoctorName(selectedId);
    setDoctorNameId(doctor ? doctor.doctorId : '');
    setSelectedDoctor(doctor);
  };

  const getColorBasedOnCategory = (category) => {
    switch (category) {
      case '1':
        return '#878cde';
      case '2':
        return '#74b874';
      case '3':
        return '#a18467';
      default:
        return '#9b7d7d';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const database = firebase.database();
        const snapshot = await database.ref('/Doctor Attendance').once('value');
        const roomData = snapshot.val();
        const latestEvents = {};

        for (const key in roomData) {
          if (roomData.hasOwnProperty(key)) {
            const eventData = roomData[key];
            const eventId = eventData.doctorName + eventData.selectedDate;

            if (!latestEvents[eventId] || new Date(eventData.selectedDate) >= new Date(latestEvents[eventId].selectedDate)) {
              latestEvents[eventId] = eventData;
            }
          }
        }

        const events = Object.values(latestEvents).map(eventData => {
          const title = eventData.status === 'Present'
            ? `${eventData.fromHour}:${eventData.fromMinute} to ${eventData.toHour}:${eventData.toMinute}`
            : eventData.status;

          return {
            title,
            start: new Date(eventData.selectedDate),
            allDay: true,
            backgroundColor: getColorBasedOnCategory(eventData.doctorId)
          };
        });

        setCalendarEvents(events);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const renderEventContent = (eventInfo) => {
    return (
      <div className="event-content" style={{ backgroundColor: eventInfo.backgroundColor, textAlign: 'center' }}>
        {eventInfo.timeText && <div className="event-time">{eventInfo.timeText}</div>}
        <div className="event-title">{eventInfo.event.title}</div>
      </div>
    );
  };

  return (
    <div className="App">
    <Header />
    <Loader/>
    {/* Content Start */}
    <div className="container-fluid" style={{ marginTop: "200px" }}>
      <h1 style={{ color: "#853f91", textAlign: "center" }}>Doctor Attendance</h1><br></br>
      {/* Color box container table */}
      <div className="col-md-12" style={{ marginTop: "40px" }}>
          <div className='row' style={{display:"flex", justifyContent:"left"}}>
            {options.map(option => (
              <div className="col-auto" key={option.value}>
                <div className="box" style={{ backgroundColor: getColorBasedOnCategory(option.value), textAlign:"center", borderRadius:"5px", padding: "10px", margin: "5px"}}>
                  {option.name}
                </div>
              </div>
            ))}
          </div>
        </div><br></br>
      {/* Calendar */}
      <div className="col-md-12">
        <div className="mb-3 calendar-container">
          <Fullcalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView={"dayGridMonth"}
            headerToolbar={{
              start: "today prev,next",
              center: "title",
              end: "dayGridMonth,timeGridWeek,timeGridDay",
              className: "fc-header-toolbar"
            }}
            buttonText={{
              today: 'Today',
              month: 'Month',
              week: 'Week',
              day: 'Day',
            }}
            height={"60vh"}
            eventContent={renderEventContent}
            events={calendarEvents}
            themeSystem="customTheme"
          />
        </div>
      </div>

      {/* Form */}
      <div className="col-md-12">
        <div className="mb-3">
          <form onSubmit={handleSubmit}>
            <div className="row">
            <div className="col-md-2">
                <label htmlFor="doctorName" className="form-label">Doctor Name</label>
                <Multiselect
                  options={options}
                  selectedValues={doctorName}
                  onSelect={setDoctorName}
                  onRemove={setDoctorName}
                  displayValue="name"
                  placeholder="Select Doctor"
                  className="checkdrop"
                 />
              </div>

              <div className="col-md-2 " style={{width:"150px"}}>
                <label htmlFor="datepicker" className="form-label">Select Date</label>
                <DatePicker
                  className="form-control"
                  id="datepicker"
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                />
              </div>

              <div className="col-md-3 mb-3">
                <label htmlFor="status" className="form-label">Status</label>
                <div className="d-flex">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="present"
                      value="Present"
                      checked={status === 'Present'}
                      onChange={(e) => setStatus(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="present" style={{fontSize:"12px",fontWeight:"normal"}}>
                      Present
                    </label>
                  </div>
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="leave"
                      value="Leave"
                      checked={status === 'Leave'}
                      onChange={(e) => setStatus(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="leave" style={{fontSize:"12px",fontWeight:"normal"}}>
                      Leave
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="halfDayLeave"
                      value="Half Day Leave"
                      checked={status === 'Half Day Leave'}
                      onChange={(e) => setStatus(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="halfDayLeave" style={{fontSize:"12px",fontWeight:"normal"}}>
                      Half Day Leave
                    </label>
                  </div>
                </div>
              </div>
            
             
              <div className="col-md-2 mb-3 time-select-container" style={{width:"150px"}}>
                <label htmlFor="fromTime" className="form-label">From</label>
                <div className="input-group">
                  <select
                    className="form-control"
                    id="fromHour"
                    value={fromHour}
                    onChange={(e) => setFromHour(e.target.value)}
                   
                  >
                    {[...Array(24)].map((_, hour) => (
                      <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                    ))}
                  </select>
                  <span className="input-group-text">:</span>
                  <select
                    className="form-control"
                    id="fromMinute"
                    value={fromMinute}
                    onChange={(e) => setFromMinute(e.target.value)}
                  >
                    {[...Array(60)].map((_, minute) => (
                      <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-2 mb-3 time-select-container" style={{width:"150px"}}>
                <label htmlFor="toTime" className="form-label">To</label>
                <div className="input-group">
                  <select
                    className="form-control"
                    id="toHour"
                    value={toHour}
                    onChange={(e) => setToHour(e.target.value)}
                  >
                    {[...Array(24)].map((_, hour) => (
                      <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                    ))}
                  </select>
                  <span className="input-group-text">:</span>
                  <select
                    className="form-control"
                    id="toMinute"
                    value={toMinute}
                    onChange={(e) => setToMinute(e.target.value)}
                  >
                    {[...Array(60)].map((_, minute) => (
                      <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
           
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <button type="submit" className="btn btn-primary submitdoct-atte" style={{ width: "150px" }}>Submit</button>
              </div>
            </div>

            {successMessage2 && <div className="alert alert-success mt-3">{successMessage2}</div>}
            {errorMessage2 && <div className="alert alert-danger mt-3">{errorMessage2}</div>}
          </form>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}

export default AttendanceDoctor;
