import React,{useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from 'axios';
import './Adminsm.css';
import Loader from "./Loader";

const Adminsm=()=>{
    const [links, setLinks] = useState(Array(9).fill(''));
    const [titles, setTitles] = useState(Array(9).fill(''));
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
  
    useEffect(()=>{
      document.title="Social Media | Physiora"
    },[]);


    useEffect(() => {
      // Fetch initial data from the database
      axios.get('https://us-central1-the-physiora.cloudfunctions.net/api/get-links')
        .then(response => {
          setLinks(response.data.links);
          setTitles(response.data.titles);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }, []);
  
    const handleLinkChange = (index, value) => {
      const newLinks = [...links];
      newLinks[index] = value;
      setLinks(newLinks);
    };
  
    const handleTitleChange = (index, value) => {
      const newTitles = [...titles];
      newTitles[index] = value;
      setTitles(newTitles);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const data = { links, titles };
      axios.post('https://us-central1-the-physiora.cloudfunctions.net/api/update-links', data)
        .then(response => {
          console.log('Data updated successfully:', response.data);
          setSuccessMessage('Data updated successfully!');
        })
        .catch(error => {
          console.error('Error updating data:', error);
        });
    };
  
    if (loading) {
      return <div>Loading...</div>;
    }
    return(
        <div className="App">
        <Header/>
        <Loader/>
        <form className="form4" onSubmit={handleSubmit} >
        <h1 className="text-center" style={{color:"#853f91"}}>Instagram Links</h1><br></br>
        {links.map((link, index) => (
          <div className="admdiv" key={index}>
            <input
              type="text"
              placeholder={`Link ${index + 1}`}
              value={link}
              onChange={(e) => handleLinkChange(index, e.target.value)}
            />
            <input
              type="text"
              placeholder={`Title ${index + 1}`}
              value={titles[index]}
              onChange={(e) => handleTitleChange(index, e.target.value)}
            />
          </div>
        ))}
        <button type="submit">Submit</button>
      </form>
      {successMessage && <div className="success-message1">{successMessage}</div>}
      <Footer/>
      </div>
    );
}

export default Adminsm;