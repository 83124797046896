import React, { useEffect, useState} from 'react';
import './Header.css';
import $ from "jquery";
import firebase from 'firebase/compat/app';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image1 from "./images/Brain & Spine PNG.png";
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';


const Header = () => {
  const [headerClass, setHeaderClass] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll >= 10) {
        setHeaderClass("background-header header-reduced");
      } else {
        setHeaderClass("");
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    handleResize();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // jQuery code for mobile menu and window resize handling
    var width = $(window).width();
    $(window).resize(function () {
      if (width > 767 && $(window).width() < 767) {
        window.location.reload();
      } else if (width < 767 && $(window).width() > 767) {
        window.location.reload();
      }
    });

    // Menu Dropdown Toggle
    if ($(".menu-trigger").length) {
      $(".menu-trigger").on("click", function () {
        $(this).toggleClass("active");
        $(".header-area .nav").slideToggle(300);
      });
    }

    const dropdownOpener = $(".main-nav ul.nav .has-sub > a");

    // Open/Close Submenus
    if (dropdownOpener.length) {
      dropdownOpener.each(function () {
        var _this = $(this);

        _this.on("tap click", function (e) {
          var thisItemParent = _this.parent("li"),
            thisItemParentSiblingsWithDrop =
              thisItemParent.siblings(".has-sub");

          if (thisItemParent.hasClass("has-sub")) {
            var submenu = thisItemParent.find("> ul.sub-menu");

            if (submenu.is(":visible")) {
              submenu.slideUp(450, "easeInOutQuad");
              thisItemParent.removeClass("is-open-sub");
            } else {
              thisItemParent.addClass("is-open-sub");

              if (thisItemParentSiblingsWithDrop.length === 0) {
                thisItemParent
                  .find(".sub-menu")
                  .slideUp(400, "easeInOutQuad", function () {
                    submenu.slideDown(250, "easeInOutQuad");
                  });
              } else {
                thisItemParent
                  .siblings()
                  .removeClass("is-open-sub")
                  .find(".sub-menu")
                  .slideUp(250, "easeInOutQuad", function () {
                    submenu.slideDown(250, "easeInOutQuad");
                  });
              }
            }
          }

          e.preventDefault();
        });
      });
    }
  }, []);

  
  //isloggedin
  const [isLoggedIn, setIsLoggedIn] = useState(false);
useEffect(() => {
  // Check if user is already logged in
  const unsubscribe=firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  });
  return unsubscribe
}, []);


//logout
const handleLogout = () => {
  firebase.auth().signOut()
    .then(() => {
      setIsLoggedIn(false);
      // Redirect to home page or do any necessary clean up
      window.location.href = '/';
    })
    .catch((error) => {
      console.error('Sign out error:', error);
    });
};

const [dropdownOpen, setDropdownOpen] = useState(false);

const [dropdownOpen1, setDropdownOpen1] = useState(false);

const handleMouseEnter = () => {
  setDropdownOpen(true);
};

const handleMouseEnter1 = () =>{
  setDropdownOpen1(true);
};

// Function to handle mouse leave event
const handleMouseLeave = () => {
  setDropdownOpen(false);
};

const handleMouseLeave1 = () => {
  setDropdownOpen1(false);
};

const [isAdmin, setIsAdmin] = useState(false);

  
useEffect(()=>{
  const checkAdminStatus=()=>{
  firebase.auth().onAuthStateChanged((user)=>{
    if(user){
      const userEmail=user.email;
      firebase.database().ref('Admin').orderByValue().equalTo(userEmail).once('value')
        .then((snapshot)=>{
          if (snapshot.exists()) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        }).catch((error)=>{
          console.error("Error checking admin status:",error);
        })
    }else{
      setIsAdmin(false);
    }

  })
  }
  checkAdminStatus();
},[]);

const [showServices, setShowServices] = useState(false);

const [isMobile, setIsMobile] = useState(false);

const toggleServices = () => {
  setShowServices(!showServices);
};

const [showProfileDropdown, setShowProfileDropdown] = useState(false);

const toggleProfileDropdown = () => {
  setShowProfileDropdown(!showProfileDropdown);
};

  return (
    
    // <header className={`header-area header-sticky ${headerClass}`}>
    //   <div className="header-content">
    //     <div className="title">
    //     <h1 className="animated-title">THE PHYSIORA</h1>
    //     </div>
    //     <nav>
    //       <ul>
    //         <li><a href="/">Home</a></li>
    //         <li><a href="/contact">Contact</a></li>
    //         <li><a href="/testimonial">Testimonial</a></li>

    //       </ul>
    //     </nav>
    //   </div>
    // </header>
     
    <header className={`header-area header-sticky ${headerClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="main-nav">
                <img className="icoimage" src={Image1} alt="Website Icon" style={{ width: '70px' }} />
                {/* ***** Logo Start ***** */}
                <a href="/" className="logo">
                  {/* <h1>{headerData.logo.Text}</h1> */}
                </a>
                {/* ***** Logo End ***** */}
                {/* ***** Search Start ***** */}
               
                <div className="title" style={{marginLeft:"20px",marginTop:"10px"}}>
                <h1 className="animated-title">THE PHYSIORA</h1>
                </div>
                <div className="search-input">
                  <form id="search" action="#">
                    <input
                      type="text"
                      placeholder="Type Something"
                      id="searchText"
                      name="searchKeyword"
                    />
                    <i className="fa fa-search" />
                  </form>
                </div>
                {/* ***** Search End ***** */}
                {/* ***** Menu Start ***** */}
                <ul className="nav">
                <div className='henav'>
                  {/* <li>
                   
                     <a href="/home" style={{color:"#853f91"}}>Home</a> 
                   
                  </li> */}
                 <li>
                  <NavLink exact to="/" activeClassName="current" style={{ color: '#853f91' }}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" activeClassName="current" style={{ color: '#853f91' }}>
                    About
                  </NavLink>
                </li>
                {!isMobile && (
                    <div className='dropdown1' onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
                      <li>
                        <a className="servicedrop" style={{ color: '#853f91', borderColor: "#f2c5fa" }}>
                          Services{' '}
                          <FontAwesomeIcon
                            icon={dropdownOpen1 ? faCaretUp : faCaretDown}
                            style={{ marginLeft: '5px' }}
                          />
                        </a>
                      </li>
                      <div className={`dropdown-menu ${dropdownOpen1 ? 'show' : ''}`} style={{ backgroundColor: '#f2c5fa', border: '1px solid #f2c5fa', maxHeight: '500px', textAlign: 'center' }}>
                        <NavLink to="/specialization" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Specialization</NavLink>
                        <NavLink to="/treatment" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Treatment</NavLink>
                        <NavLink to="/fitforfifty" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Fit to Fifty</NavLink>
                        <NavLink to="/corporatecorrection" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Corporate Correction</NavLink>
                      </div>
                    </div>
                  )}
                  {isMobile && (
                    <li>
                      <a onClick={toggleServices} style={{ color: '#853f91', borderColor: "#f2c5fa" }}>
                        Services{' '}
                        <FontAwesomeIcon
                          icon={showServices ? faCaretUp : faCaretDown}
                          style={{ marginLeft: '5px' }}
                        />
                      </a>
                      {showServices && (
                        <div style={{ backgroundColor: '#f2c5fa', border: '1px solid #f2c5fa', maxHeight: '500px', textAlign: 'center' }}>
                          <NavLink to="/specialization" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Specialization</NavLink>
                          <NavLink to="/treatment" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Treatment</NavLink>
                          <NavLink to="/fitforfifty" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Fit to Fifty</NavLink>
                          <NavLink to="/corporatecorrection" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Corporate Correction</NavLink>
                        </div>
                      )}
                    </li>
                  )}

                
                <li>
                  <NavLink to="/testimonial" activeClassName="current" style={{ color: '#853f91' }}>
                    Testimonial
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="current" style={{ color: '#853f91' }}>
                    Contact
                  </NavLink>
                </li>
                {!isMobile && (
                    <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                      <FontAwesomeIcon
                        icon={faUser}
                        aria-hidden="true"
                        className='mbileico'
                        style={{ color: '#853f91' }}
                      /><FontAwesomeIcon
                        icon={dropdownOpen ? faCaretUp : faCaretDown}
                        style={{ marginLeft: '5px', color: '#853f91' }}
                      />
                      <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ backgroundColor: '#f2c5fa', border: '1px solid #f2c5fa', maxHeight: '500px', textAlign: 'center' }}>
                        {isLoggedIn ? (
                          <>
                            <NavLink to="/profile" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Profile</NavLink>
                            <NavLink to="/allpayment" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>All Payments</NavLink>
                            {isAdmin && <NavLink to="/adminpage" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>AdminPage</NavLink>}
                            {isAdmin && <NavLink to="/paymentDetails" className="dropdown-item" style={{textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Payment Details</NavLink>}
                            {isAdmin && <NavLink to="/SEO" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>SEO</NavLink>}
                            {isAdmin && <NavLink to="/admin-filter" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Google Review</NavLink>}
                            {isAdmin && <NavLink to="/doctor-schedule" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Add Doctor</NavLink>}
                            {isAdmin && <NavLink to="/doctor-attendance" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Attendance</NavLink>}
                            {isAdmin && <NavLink to="/doctortime" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Time Sheet</NavLink>}
                            {isAdmin && <NavLink to="/payslip" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Payslip</NavLink>}
                            {isAdmin && <NavLink to="/adminsm" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Social Media</NavLink>}
                            <NavLink to="/" className="dropdown-item" onClick={handleLogout} style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Logout</NavLink>
                          </>
                        ) : (
                          <NavLink to="/auth" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Login</NavLink>
                        )}
                      </div>
                    </div>
                  )}
                  {isMobile && (
                    <li>
                      <a onClick={toggleProfileDropdown} style={{ color: '#853f91', borderColor: "#f2c5fa" }}>
                        <FontAwesomeIcon
                          icon={faUser}
                          aria-hidden="true"
                          className='mbileico'
                          style={{ color: '#853f91' }}
                        />
                        <FontAwesomeIcon
                          icon={showProfileDropdown ? faCaretUp : faCaretDown}
                          style={{ marginLeft: '5px', color: '#853f91' }}
                        />
                      </a>
                      {showProfileDropdown && (
                        <div style={{ backgroundColor: '#f2c5fa', border: '1px solid #f2c5fa', maxHeight: '500px', textAlign: 'center' }}>
                          {isLoggedIn ? (
                            <>
                              <NavLink to="/profile" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Profile</NavLink>
                              <NavLink to="/allpayment" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>All Payments</NavLink>
                              {isAdmin && <NavLink to="/adminpage" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>AdminPage</NavLink>}
                              {isAdmin && <NavLink to="/paymentDetails" className="dropdown-item" style={{textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Payment Details</NavLink>}
                              {isAdmin && <NavLink to="/SEO" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>SEO</NavLink>}
                              {isAdmin && <NavLink to="/admin-filter" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Google Review</NavLink>}
                              {isAdmin && <NavLink to="/doctor-schedule" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Add Doctor</NavLink>}
                              {isAdmin && <NavLink to="/doctor-attendance" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Attendance</NavLink>}
                              {isAdmin && <NavLink to="/doctortime" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Time Sheet</NavLink>}
                              {isAdmin && <NavLink to="/payslip" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Payslip</NavLink>}
                              {isAdmin && <NavLink to="/adminsm" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Social Media</NavLink>}
                            
                              <NavLink to="/" className="dropdown-item" onClick={handleLogout} style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Logout</NavLink>
                            </>
                          ) : (
                            <NavLink to="/auth" className="dropdown-item" style={{ textTransform: 'uppercase', fontSize: '14px', color: '#853f91', backgroundColor: 'transparent' }}>Login</NavLink>
                          )}
                        </div>
                      )}
                    </li>
                  )}
                </div>
              </ul>
             
              <a className="menu-trigger" style={{ backgroundColor: '#853f91' }}>
                <span>Menu</span>
              </a>
            </nav>
            </div>
          </div>
        </div>
      </header>
    
  );
};

export default Header;
