import React from "react";
import "./Achievementbriefly.css";
import Image1 from "./images/Achievements Mam.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Achievementbrief=()=>{
    return(
      <>
        <h1 className="mt-4 text-center" style={{color:"#853f91"}}>Achievements</h1>
        <div className="achievement-section">
        
        <div className="achievement-image">
          <img src={Image1} alt="Achievement" />
        </div>
        <div className="achievement-content col-9" >
          <h2>I.I.M.U.N. (India's International Movement to Unite Nations)</h2>
          <p>
            Recognized as South’s Best Young Physio at Parul University, Invited
            as a Guest speaker to host the Physio Summit 2024 at PU Talks
          </p>
          <p>
            Treated 3000+ patients globally both Offline & Online and have avoided
            around 100+ Surgeries
          </p>
          <ul>
            <li>
              <FontAwesomeIcon icon={faStar} className="star-icon" /> Won Best Start Up Enterpreneur Award at SHE AWARDS
            </li>
            <li>
              <FontAwesomeIcon icon={faStar} className="star-icon" /> Nominated for Best Health Care Professional of the Year
            </li>
            <li>
              <FontAwesomeIcon icon={faStar} className="star-icon" /> Recognized as Women hero in Threos Round Table
            </li>
            <li>
              <FontAwesomeIcon icon={faStar} className="star-icon" /> Awarded as BEST PHYSIO CONTENT CREATOR OF 2023 AT SOUTH INDIAN TALENT AWARDS
            </li>
          </ul>
        </div>
      </div>
      </>
    );
}

export default Achievementbrief;