import React,{useState, useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Comingsoon from "./Comingsoon";
import './style.css';
import ColorPalate from "./colorpalate";
import Contact from "./Contact";
import "./Contact.css";
import AdminPage from "./Adminpage";
import HomePage from "./Homepage";
import Aboutpage from "./Aboutpage";
import Servicepage from "./Servicepage";
import AuthForm from "./signin";
import Profile from "./Profile";
import Testimonialfilter from "./Testimonialfilter";
import DoctorSchedule from "./DoctorSchedule";
import AttendanceDoctor from "./AttendanceDoctor";
import TableDoctorSchedule from "./TableDoctorSchedule";
import PayslipForm from "./Payslip";
import Adminsm from "./Adminsm";
import Testimonialpage from "./Testimonialpage";
import SEO from "./SEO";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage'
import Specialization from "./Specialization";
import Treatment from "./treatments";
import Testing from "./testing";
import EditDoctor from "./EditDoctorSchedule";
import UpdateAttendance from "./UpdateAttendance";
import FitForFifty from "./FitforFifty";
import CorporateCorrection from "./CorporateCorrection";
import ScrollToTop from "./Scrollpage";
import PaymentPage from "./PaymentPage";
import PaymentDetails from "./PaymentDetails";
import PaymentStatus from "./PaymentStatus";
import AdminPayment from "./AdminPayment";

const App=()=>{
 
//meta type

const [metaTitle, setMetaTitle] = useState('');
const [keywords, setKeywords] = useState('');
const [primaryKeywords, setPrimaryKeywords] = useState('');
const [secondaryKeywords, setSecondaryKeywords] = useState('');
const [message3, setMessage3] = useState(''); 


useEffect(() => {
  const metaDataRef = firebase.database().ref('metadata');

  // Fetch metadata from the database
  metaDataRef.once('value').then((snapshot) => {
      const metaData = snapshot.val();
      if (metaData) {
          setMetaTitle(metaData.metaTitle || '');
          setKeywords(metaData.keywords || '');
          setPrimaryKeywords(metaData.primaryKeywords || '');
          setSecondaryKeywords(metaData.secondaryKeywords || '');

      }
  }).catch((error) => {
      console.error('Error fetching metadata:', error);
  });
}, []);

  useEffect(() => {



    const keywordsMeta = document.querySelector('meta[name="keywords"]');
    const primaryKeywordsMeta = document.querySelector('meta[name="primary keywords"]');
    const secondaryKeywordsMeta = document.querySelector('meta[name="secondary keywords"]');

    console.log('Keywords meta content:', keywordsMeta ? keywordsMeta.content : null);
    console.log('Primary keywords meta content:', primaryKeywordsMeta ? primaryKeywordsMeta.content : null);
    console.log('Secondary keywords meta content:', secondaryKeywordsMeta ? secondaryKeywordsMeta.content : null);

    if (keywordsMeta) {
        keywordsMeta.setAttribute('content', keywords);
    }

    if (primaryKeywordsMeta) {
        primaryKeywordsMeta.setAttribute('content', primaryKeywords);
    }

    if (secondaryKeywordsMeta) {
        secondaryKeywordsMeta.setAttribute('content', secondaryKeywords);
    }
}, [metaTitle,keywords, primaryKeywords, secondaryKeywords]);



const [isAdmin, setIsAdmin] = useState(false);

  
useEffect(()=>{
  const checkAdminStatus=()=>{
  firebase.auth().onAuthStateChanged((user)=>{
    if(user){
      const userEmail=user.email;
      firebase.database().ref('Admin').orderByValue().equalTo(userEmail).once('value')
        .then((snapshot)=>{
          if (snapshot.exists()) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        }).catch((error)=>{
          console.error("Error checking admin status:",error);
        })
    }else{
      setIsAdmin(false);
    }

  })
  }
  checkAdminStatus();
},[]);

  return(
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        
        <Route path="/" element={<HomePage/>}/>
        <Route path="/color" element={<ColorPalate/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/services" element={<Servicepage/>}/>
        {isAdmin &&<Route path="/adminpage" element={<AdminPage/>}/>}
        <Route path="/auth" element={<AuthForm/>}/>
        <Route path="/about" element={<Aboutpage/>}/>
        <Route path="/profile" element={<Profile/>}/>
        {isAdmin &&<Route path="/admin-filter" element={<Testimonialfilter/>}/>}
        {isAdmin &&<Route path="/doctor-schedule" element={<DoctorSchedule/>}/>}
        {isAdmin &&<Route path="/doctor-attendance" element={<AttendanceDoctor/>}/>}
        {isAdmin &&<Route path="/doctortime" element={<TableDoctorSchedule/>}/>}
        {isAdmin &&<Route path="/payslip" element={<PayslipForm/>}/>}
        {isAdmin &&<Route path="/adminsm" element={<Adminsm/>}/>}
        <Route path="/testimonial" element={<Testimonialpage/>}/>
        {isAdmin &&<Route path="/SEO" element={<SEO/>}/>}
        <Route path="/specialization" element={<Specialization/>}/>
        <Route path="/treatment" element={<Treatment/>}/>
        <Route path="/testing" element={<Testing/>}/>
        <Route path="/doctor-edit/:doctorId" element={<EditDoctor/>}/>
        <Route path="/attendance-update/:attendanceId" element={<UpdateAttendance/>}/>
        <Route path="/fitforfifty" element={<FitForFifty/>}/>
        <Route path="/corporatecorrection" element={<CorporateCorrection/>}/>
        <Route path="/PaymentPage" element={<PaymentPage/>}/>
        <Route path="/allpayment" element={<PaymentDetails/>}/>
        <Route path="/paymentStatus" element={<PaymentStatus/>}/>
        <Route path="/paymentDetails" element={<AdminPayment/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;