import React,{useEffect} from "react";
import About from "./About";
import Header from "./Header";
import Footer from "./Footer";
import Achievement from "./Achievement";
import Testimonial from "./Testimonial";
import Slide from "./slide";
import Appointment from "./Appointment";
import Loader from "./Loader.js";
import Achievementbrief from "./Achievementbrief.js";

const Aboutpage=()=>{
    useEffect(()=>{
        document.title="About | Physiora"
      },[]);
    return(
        <div className="App">
            <Header/><br></br><br></br><br></br><br></br>
            <Loader/>
            <Slide/>
            <About/><br></br><br></br>
            <Achievementbrief/><br></br>
            <Testimonial/>
            <Achievement/>
           
            <Footer/>
        </div>
    );
}

export default Aboutpage;