import React from "react";
import image1 from "./images/8443649.jpg";
import image2 from "./images/about-2.jpg";
import "./about.css";

const About = () => {
  return (
    <>
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-flex flex-column">
                <img
                  className="img-fluid rounded w-75 align-self-end"
                  src={image1}
                  alt=""
                  style={{ marginRight: "20px" }}
                />
                {/* <img
                  className="img-fluid rounded w-50 bg-white pt-3 pe-3"
                  src={image2}
                  alt=""
                  style={{ marginTop: "-25%" }}
                /> */}
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <p className="d-inline-block border rounded-pill py-1 px-4">
                About Us
              </p>
              {/* <h1 className="mb-4" style={{color:"#853f91"}}>Why You Should Trust Us? Get Know About Us!</h1>
              <p>
              Welcome to The Physiora, where your health and well-being are our top priorities. Our mission is to provide exceptional physiotherapy services tailored to meet your unique needs, helping you achieve optimal physical function and improved quality of life.
              </p><br></br>
              <p className="mb-4">
              We believe in a holistic approach to physiotherapy that addresses the root cause of your pain or dysfunction rather than just treating the symptoms.
              </p>
              <p>
                <i className="far fa-check-circle text-primary me-3" />
                Personalized Treatment Plans
              </p>
              <p>
                <i className="far fa-check-circle text-primary me-3" />
                Comprehensive Services
              </p>
              <p>
                <i className="far fa-check-circle text-primary me-3"/>
                State-of-the-Art Facility
              </p> */}
              {/* <a className="btn rounded-pill py-3 px-5 mt-3" href="" style={{backgroundColor:"#853f91", color:"white"}}>
                Read More
              </a> */}
              <div className="container mt-3">
                <div
                  className="nav flex-row nav-pills ftco-animate"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className="nav-link active"
                    id="v-pills-mission-tab"
                    data-toggle="pill"
                    href="#v-pills-mission"
                    role="tab"
                    aria-controls="v-pills-mission"
                    aria-selected="true"
                    style={{ color: "black", padding: "8px", backgroundColor: "#f2c5fa" }}
                  >
                    Our Mission
                  </a>
                  {/* <a
                    className="nav-link"
                    id="v-pills-goal-tab"
                    data-toggle="pill"
                    href="#v-pills-goal"
                    role="tab"
                    aria-controls="v-pills-goal"
                    aria-selected="false"
                    style={{ color: "black", padding: "8px", backgroundColor: "#f2c5fa", marginLeft: "10px" }}
                  >
                    Our Vision
                  </a> */}
                </div><br />
                <div className="col-12">
                  <div className="tab-content ftco-animate" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-mission"
                      role="tabpanel"
                      aria-labelledby="v-pills-mission-tab"
                    >
                      <div>
                       
                        {/* <h2 className="mb-3" style={{ fontSize: "25px", color: "#853f91" }}>STRENGTH OF PHYSIORA TEAM</h2> */}
                        <ul className="custom-list" style={{ textAlign: "justify" }}>
                          <li>We Understand the goal of the patient</li>
                          <li>We Analyse the cause of pain</li>
                          <li>We treat the cause of pain</li>
                          <li>We Review the patient regularly</li>
                          <li>We make sure to reduce the pain in few possible days!!</li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-goal"
                      role="tabpanel"
                      aria-labelledby="v-pills-goal-tab"
                    >
                      <div>
                        <h2 className="mb-4" style={{ fontSize: "25px", color: "#853f91" }}>Help Our Patients Needs</h2>
                        <p style={{ textAlign: "justify" }}>
                          To be your preferred Physiotherapy Service provider, Recognized for excellence and professionalism, continually adopting to meet your evolving needs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
    </>
  );
}

export default About;
