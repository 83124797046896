import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';

const PDFDocument = ({ paymentsData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image src="/path-to-your-logo/logo.png" style={{ width: 50, height: 50 }} />
        <Text style={{ fontSize: 25, color: "#853f91", textAlign: "center", marginLeft: 20 }}>THE PHYSIORA</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}><Text>S.No</Text></View>
            <View style={styles.tableColHeader}><Text>Patient Name</Text></View>
            <View style={styles.tableColHeader}><Text>Phone</Text></View>
            <View style={styles.tableColHeader}><Text>Appointment Date</Text></View>
            <View style={styles.tableColHeader}><Text>Appointment Time</Text></View>
            <View style={styles.tableColHeader}><Text>Booking Date</Text></View>
            <View style={styles.tableColHeader}><Text>Email</Text></View>
            {/* <View style={styles.tableColHeader}><Text>Payment ID</Text></View> */}
            <View style={styles.tableColHeader}><Text>Status</Text></View>
            <View style={styles.tableColHeader}><Text>Appointment Status</Text></View>
          </View>
          {paymentsData.map((payment, index) => (
            <View
              key={index}
              style={index % 2 === 0 ? { ...styles.tableRow, backgroundColor: '#DDDDDD' } : styles.tableRow}
            >
              <View style={styles.tableCol}><Text>{index + 1}</Text></View>
              <View style={styles.tableCol}><Text>{payment.patientName}</Text></View>
              <View style={styles.tableCol}><Text>{payment.phone}</Text></View>
              <View style={styles.tableCol}><Text>{moment(payment.AppointmentDate).format("DD-MM-YYYY")}</Text></View>
              <View style={styles.tableCol}><Text>{payment.AppointmentTime}</Text></View>
              <View style={styles.tableCol}><Text>{payment.date}</Text></View>
              <View style={styles.tableCol}><Text>{payment.email}</Text></View>
              {/* <View style={styles.tableCol}><Text>{payment.paymentId}</Text></View> */}
              <View style={styles.tableCol}><Text>{payment.status === "captured" ? "success" : payment.status}</Text></View>
              <View style={styles.tableCol}><Text>{payment.appointmentStatus || "No"}</Text></View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer}>
        <Text>OMR, Egattur, Chennai</Text>
        <Text>thephysiora@gmail.com</Text>
        <Text>+91 89254 49512</Text>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 12,
  },
  header: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f2c5fa',
    paddingVertical: 10,
  },
  footer: {
    fontSize: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    backgroundColor: '#00ACED',
    padding: 10,
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginTop: 20,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '11.11%', // Adjusted width for 9 columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#853f91',
    backgroundColor: '#853f91',
    padding: 5,
  },
  tableCol: {
    width: '11.11%', // Adjusted width for 9 columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
    padding: 5,
    backgroundColor: 'white',
    fontSize: '10px',
  },
});

export default PDFDocument;
