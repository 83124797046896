import React, { useState } from 'react';
import './Section.css';
import Button from './Button';
import image5 from './images/demo6.png';


const Section = ({ title, children }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = () => {
    alert('Button clicked!');
  };

  return (
    <div className='row12' style={{marginTop:"80px"}}>
      <div 
        className="image-container" 
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleMouseLeave}
      >
        <img className="image6" src={image5} alt="Demo" />
        {hovered }
      </div>
      <div className="section1" style={{height:"350px"}}>
        <h2>Physiotheraphy Services</h2>
        <p>Our physiotheraphy services help you to restore, maintain, and maximize your strength, function, and movement. We use a variety of techniques such as manual therapy, exercise, and education to address your specific needs and goals.</p>
        <Button onClick={handleClick}>Learn More</Button>
      </div>
    </div>
  );
};

export default Section;
