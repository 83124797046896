import React from "react";
import images1 from "./images/demo1.jpg";
import images2 from "./images/demo2.jpg";
import images3 from "./images/demo3.jpg";
import images4 from "./images/demo4.jpg";
import "./Gallery.css";


const Gallery=()=>{
    return(
        <div className="row12">
          <div className="gallery">
          <img src={images1} alt="a hot air balloon" />
          <img
            src={images2}
            alt="a sky photo of an old city"
          />
          <img src={images3} alt="a small boat" />
          <img src={images4} alt="a forest" />
        </div>
          <div className="cards_content">
            <h3 style={{color: "#853f91"}}>Physiotheraphy</h3>
            <p> Physiotherapy is treatment to restore, maintain, and make the most of a patient's mobility, function, and well-being. It can also help to reduce your risk of injury or illness in the future. It takes a holistic approach that involves the patient directly in their own care.</p>
          </div>
      </div>
    );
}

export default Gallery;