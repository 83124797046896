import React,{useState, useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Smdisplay from "./Smdisplay";
import Testimonial from "./Testimonial";
import Loader from "./Loader";

const Testimonialpage=()=>{

    useEffect(()=>{
        document.title="Testimonial | Physiora"
      },[]);
  
    return(
        <div className="App">
            <Header/>
            <Loader/>
            <div style={{marginTop:"120px"}}>
                <Testimonial/>
            </div>
                <Smdisplay/>
                
            <Footer/>
        </div>
    );
}

export default Testimonialpage;