import React from 'react';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

const PaymentButton = () => {
  const navigate = useNavigate();

  const handlePayment = () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser) {
      const userId = currentUser.uid;
      const email = currentUser.email;
      console.log("email:",email);

      const amount = '40000'; // Amount in paise (40000 paise = 400 INR)

      const currentTimestamp = moment().tz("Asia/Kolkata").format("DD/MM/YYYY, HH:mm:ss");

      const options = {
        key: 'rzp_test_X3TXsXgLXXczJk',
        amount: '40000',
        currency: 'INR',
        name: 'The Physiora',
        description: 'Test Transaction',
        handler: function (response) {
          console.log('Payment response:', response);

          const data = {
            paymentId: response.razorpay_payment_id,
            userId,
            email
          };

          console.log('Sending data to backend:', data);

          axios.post('https://us-central1-the-physiora.cloudfunctions.net/api/paymentResponse', data)
            .then((res) => {
               navigate('/paymentStatus', { state: { status: 'captured', paymentId: response.razorpay_payment_id,amount, date: currentTimestamp } });
            })
            .catch((error) => {
              navigate('/paymentStatus', { state: { status: 'failed', paymentId: response.razorpay_payment_id,amount, date: currentTimestamp } });
            });
        },
        notes: {
          address: 'Test address'
        },
        theme: {
          color: '#F37254'
        },
        method: {
          netbanking: true,
          card: true,
          upi: true,
          wallet: true
        },
        modal: {
          ondismiss: function() {
            console.log('Checkout form closed');
          }
        }
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } else {
      console.error('No user is currently signed in.');
    }
  };

  return (
    <button className='mb-4' onClick={handlePayment}>Pay Now</button>
  );
};

export default PaymentButton;
